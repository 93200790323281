import React, { useEffect, useState } from 'react';
import SEO from '../../components/seo';

import { useLocation } from '@reach/router';
import { parse } from 'query-string';

function AccountsReceivablePlatform({ location }) {
  const [annualTurnover, setAnnualTurnover] = useState(20);
  const [monthInvoices, setMonthInvoices] = useState(200);
  const [daysOfDelay, setDaysOfDelay] = useState(30);
  const [writeOff, setWriteOff] = useState(5);

  const searchParams = parse(useLocation().search);
  const { name, currency } = searchParams;

  const ComputeCOI = () => {
    const costOfInvoices = ((10 * 30) / 60) * 12 * monthInvoices;
    const realTurnOver = annualTurnover * 1000000;
    const interestRate = 4;
    const costOfFinancing = (realTurnOver * interestRate * daysOfDelay) / 36500;
    return (realTurnOver / 100) * writeOff + costOfInvoices + costOfFinancing;
  };
  return (
    <>
      <SEO
        title="The hidden cost of your receivables"
        path={location.pathname}
      />

      <div className="px-48">
        <div className="lg:grid lg:grid-cols-2 pt-10 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
          <div className="mx-auto max-w-full lg:px-8">
            <div className="mt-44 max-h-max max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
              <h1 className="text-4xl tracking-tight font-bold text-gray-200 sm:text-4xl md:text-6xl">
                {name && <span className="block">{name}, </span>}
                <span className="inline bg-gradient-to-r from-purple-tb-400 bg-purple-tb-400 via-orange-tb-400 bg-clip-text font-display tracking-tight text-transparent">
                  How much working capital are you losing because of overdue
                  invoices?
                </span>
              </h1>
            </div>
          </div>
          <div className="mx-auto pt-8">
            <div className="flex max-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
              <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                  <h1 className="text-3xl tracking-tight font-bold text-gray-700 sm:text-3xl md:text-xl py-4">
                    The hidden cost of your receivables
                  </h1>
                  <form className="space-y-6" action="#" method="POST">
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Revenue (in milions)
                      </label>
                      <div className="mt-1">
                        <input
                          id="annual_turnover"
                          name="annual_turnover"
                          type="number"
                          value={annualTurnover}
                          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          onChange={(event) =>
                            setAnnualTurnover(event.target.value)
                          }
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Monthly outgoing invoices
                      </label>
                      <div className="mt-1">
                        <input
                          id="monthly_invoices"
                          name="monthly_invoices"
                          type="number"
                          value={monthInvoices}
                          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          onChange={(event) =>
                            setMonthInvoices(event.target.value)
                          }
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Average payment terms: {daysOfDelay} days
                      </label>
                      <div className="mt-1">
                        <input
                          type="range"
                          className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-70"
                          id="days_of_delay"
                          min={0}
                          max={200}
                          value={daysOfDelay}
                          onChange={(event) =>
                            setDaysOfDelay(event.target.value)
                          }
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Bad debt: {writeOff}%
                      </label>
                      <div className="mt-1">
                        <input
                          type="range"
                          className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-70"
                          id="write_off"
                          min={0}
                          max={20}
                          value={writeOff}
                          step={0.5}
                          onChange={(event) => setWriteOff(event.target.value)}
                        />
                      </div>
                    </div>

                    <div>
                      <p className="text-2xl flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                        {new Intl.NumberFormat('de-DE', {
                          style: 'currency',
                          currency: currency || 'EUR',
                        }).format(ComputeCOI())}
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AccountsReceivablePlatform;
